import 'flickity/dist/flickity.pkgd.js';
import Flickity from 'flickity';

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    productsSlider();
    logoSectionSlider();
},
};

$(window).on('resize', function () {
  $('.home-banner-ticker .ticker-wrp, .home-products-section .products-wrp .cards-wrp').flickity('resize');
});

$(window).on('load', function () {
  $('.home-banner-ticker .ticker-wrp, .home-products-section .products-wrp .cards-wrp').flickity('resize');
});

function logoSectionSlider() {
  // Play with this value to change the speed
  let tickerSpeed = 1;

  let flickity = null;
  let isPaused = false;
  const slideshowEl = document.querySelector('.home-banner-ticker .ticker-wrp');

  const update = () => {
    if (isPaused) return;
    if (flickity.slides) {
      flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
      flickity.selectedIndex = flickity.dragEndRestingSelect();
      flickity.updateSelectedSlide();
      flickity.settle(flickity.x);
    }
    window.requestAnimationFrame(update);
  };

  const pause = () => {
    isPaused = true;
  };

  const play = () => {
    if (isPaused) {
      isPaused = false;
      window.requestAnimationFrame(update);
    }
  };

  flickity = new Flickity(slideshowEl, {
    autoPlay: false,
    prevNextButtons: false,
    pageDots: false,
    draggable: true,
    wrapAround: true,
    selectedAttraction: 0.015,
    friction: 0.25,
  });

  flickity.x = 0;

  slideshowEl.addEventListener('mouseenter', pause, false);
  slideshowEl.addEventListener('focusin', pause, false);
  slideshowEl.addEventListener('mouseleave', play, false);
  slideshowEl.addEventListener('focusout', play, false);

  flickity.on('dragStart', () => {
    isPaused = true;
  });

  update();
}

function productsSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 992px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.home-products-section .products-wrp .cards-wrp').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: cellAlignValue,
  });

  $('.home-products-section').find('.flickity-button').appendTo($('.home-products-section').find('.flickity-wrp'));
}
